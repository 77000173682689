
// Animation Css

.edu_bounce_loop {
    will-change: transform;
    -webkit-animation-name: edu_bounce_loop;
    animation-name: edu_bounce_loop;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

@-webkit-keyframes edu_bounce_loop {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    50% {
        -webkit-transform: translateY(20%);
        transform: translateY(20%)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes edu_bounce_loop {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    50% {
        -webkit-transform: translateY(20%);
        transform: translateY(20%)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@-webkit-keyframes edu_rotatation_round {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg)
    }
}

@keyframes edu_rotatation_round {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg)
    }
}

.rbt-rotatation-round {
    will-change: transform;
    -webkit-animation-name: edu_rotatation_round;
    animation-name: edu_rotatation_round;
    -webkit-animation-duration: 50s;
    animation-duration: 50s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite
}


@keyframes bounceSlide {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
}

.bounce-slide {
    animation-duration: 4s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: bounceSlide;
}


@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.rotate {
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: rotate;
}
